import React from "react"
import { graphql } from "gatsby"
import BlogListPage from "../components/blog/blog-list-page"
import moment from "moment";

function BlogArchives({ pageContext, data }) {
  const { archiveDate } = pageContext;

  const formattedDate = moment(archiveDate).format('MMMM yyyy');
  const lowerCaseDate = formattedDate.toLowerCase();

  const seo = {
    metaTitle: `archive ${lowerCaseDate}`,
    metaDescription: `archive ${lowerCaseDate}`,
    lang: pageContext.strapiContext,
  }

  return (
    <BlogListPage {...{
      seo,
      data: data.allStrapiBlog.nodes,
      title: formattedDate,
      ...data,
    }} />
  )
}


export const pageQuery = graphql`
  query(
    $language: String!
    $archivePostIds: [String]
    $strapiLangKey: String!
  ) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      ...Locales
    }
    strapiGlobal(locale: {eq: $strapiLangKey}) {
      ...ContactDetails
    }
    archives: allStrapiBlog (
      filter: {
        locale: {
          eq: $strapiLangKey
        }
      }
      sort: {
        fields: publishDate,
        order: DESC
      }
    ) {
      nodes {
        publishDate
      }
    }
    recentPosts: allStrapiBlog (
      filter: {
        locale: {
          eq: $strapiLangKey
        }
      }
      sort: {
        fields: publishDate,
        order: DESC
      }
    ) {
      nodes {
        id
        title
        slug
      }
    }
    allStrapiBlog(filter: {
      locale: {
        eq: $strapiLangKey
      }
      id: {
        in: $archivePostIds
      }
    }) {
      nodes {
        id
        slug
        title
        body {
          data {
            body
          }
        }
        publishDate
      }
    }
  }
`

export default BlogArchives